import { graphql } from 'gatsby'
import * as React from 'react'
import { ListEntitiesPage } from '../components/listing/Page'
import { FilterLanguage, Portfolio } from '../Entities'
import IndexLayout from '../layouts'
import { Language } from '../state'
import { ListEntitiesHeader } from '../components/listing/Header'

interface PortfolioEnvProps {
  env: { siteMetadata: { ui: { pagination_size: number } } }
  currentPage: { context: { page_index: number } }
}

type PortfolioPageProps = {
  data: PortfolioEnvProps & {
    portfolio: {
      distinct: string[]
      edges: Portfolio
    }
  }
}

const PortfolioContainer: React.SFC<PortfolioPageProps> = props => (
  <IndexLayout>
    <Language.Consumer>
      {language => (
        <ListEntitiesPage
          dir="portfolio"
          entityDir="project"
          entities={FilterLanguage(language.selected, props.data.portfolio.edges)}
          page={props.data.currentPage.context.page_index}
          pages={Math.ceil(
            FilterLanguage(language.selected, props.data.portfolio.edges).length /
              props.data.env.siteMetadata.ui.pagination_size
          )}
          pageId="portfolio-page"
          pageLength={props.data.env.siteMetadata.ui.pagination_size}
        >
          <ListEntitiesHeader title="PORTFOLIO_TITLE" subtitle="PORTFOLIO_SUBTITLE" />
        </ListEntitiesPage>
      )}
    </Language.Consumer>
  </IndexLayout>
)

export default PortfolioContainer

export const query = graphql`
  query Portfolio($page_index: Int) {
    env: site {
      siteMetadata {
        ui {
          pagination_size
        }
      }
    }
    currentPage: sitePage(context: { page_index: { eq: $page_index }, type: { eq: "portfolio" } }) {
      context {
        page_index
      }
    }
    portfolio: allPrismicProject(sort: { fields: first_publication_date, order: DESC }) {
      distinct(field: data___link)
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          lang
          data {
            link
            title {
              text
            }
            subtitle {
              text
            }
            cover_picture {
              url
              alt
            }
          }
        }
      }
    }
  }
`
