import * as React from 'react'
import { BaseEntity } from '../../Entities'
import Page from '../Page'
import { TileList } from '../tile'
import ContactForm from '../contact/section'
import { GenericListing } from './Content'

type ListEntitiesPageProps<T extends BaseEntity> = {
  children?: React.ReactNode
  dir: string
  entityDir: string
  entities: {
    node: T
  }[]
  page: number
  pages: number
  pageLength: number
  pageId: string
}

export const ListEntitiesPage = <J extends BaseEntity, T extends ListEntitiesPageProps<J>>(
  props: T
): React.ReactElement<any> => (
  <>
    <Page id={props.pageId}>
      <GenericListing
        page={props.page}
        pages={props.pages}
        routeBuilder={(index: number) => `/${props.dir}/${index}`}
      >
        {props.children}
        <TileList
          dir={props.entityDir}
          tileClassName={props.entityDir}
          page={props.page}
          pageLength={props.pageLength}
          entities={props.entities}
        />
      </GenericListing>
    </Page>
    <ContactForm />
  </>
)
