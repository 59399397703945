import * as React from 'react'
import { PageLanguageFlags } from '../LanguageFlags'
import { Language } from '../../state'
import { PagePagination } from './Pagination'
import { PxlRaisedButton } from '../Button'

export const GenericListing: React.SFC<{
  pages: number
  page: number
  routeBuilder: (i: number) => string
}> = ({ children, pages, page, routeBuilder }) => (
  <Language.Consumer>
    {language => (
      <section>
        <div className="action banner-action">
          <PxlRaisedButton to={'/'} width={150}>
            {language.translate('ACTION_BACK')}
          </PxlRaisedButton>
          <PageLanguageFlags />
        </div>

        {children}

        <PagePagination pages={pages} page={page} routeBuilder={routeBuilder} />
      </section>
    )}
  </Language.Consumer>
)
