import React from 'react'
import { PxlRaisedButton } from '../Button'

export const PagePagination: React.SFC<{
  pages: number
  page: number
  routeBuilder: (i: number) => string
}> = ({ pages = 0, page, routeBuilder }) =>
  pages ? (
    <div className="pagination">
      <div className="action">
        <PxlRaisedButton to={routeBuilder(0)} width={50}>
          {'<'}
        </PxlRaisedButton>
      </div>

      {/* tslint:disable-line */ new Array(pages).fill(0).map((_, index) => (
        <div key={index} className="action">
          <PxlRaisedButton to={routeBuilder(index)} width={50}>
            {`${index + 1}`}
          </PxlRaisedButton>
        </div>
      ))}

      <div className="action">
        <PxlRaisedButton to={routeBuilder(page + 1)} width={50}>
          {'>'}
        </PxlRaisedButton>
      </div>
    </div>
  ) : null
