import * as React from 'react'
import { Language } from '../../state'

export const ListEntitiesHeader: React.SFC<{
  title: string
  subtitle: string
}> = props => (
  <Language.Consumer>
    {language => (
      <div className="title">
        <h1>{language.translate(props.title)}</h1>
        <h3>{language.translate(props.subtitle)}</h3>
      </div>
    )}
  </Language.Consumer>
)
